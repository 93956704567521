import translation from "./translation.json";
import stopPage from "./stopPage.json";
import authPage from "./authPage.json";
import addDropoffPage from "./addDropoffPage.json";
import types from "./types.json";
import waypointPage from "./waypointPage.json";
import topBar from "./topBar.json";

const ru = {
  translation,
  stopPage,
  authPage,
  addDropoffPage,
  types,
  waypointPage,
  topBar,
};

export default ru;
