import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Routes, Route } from "react-router-dom";
import SessionPage from "./SessionPage";
import StopPage from "./StopPage/StopPage";
import * as sessionActions from "../../ducks/app/session";
import * as navigationActions from "../../ducks/app/navigation";
import AddNotePage from "./AddCommentPage";
import AddWeightPage from "./AddWeightPage";
import { selectStop, selectWaypoint } from "../../ducks/app/session/selectors";
import { useTranslation } from "react-i18next";
import AddCargoPage from "../AddNewCargoPage";
import AddDropoffPage from "./AddDropoffPage";
import AddCargoSuccessPage from "../AddNewCargoPage/AddCargoSuccessPage";
import withAuth from "../../components/withAuth";
import { useAppDispatch } from "../../redux-store";
import WaypointPage from "./WaypointPage";
import { Waypoint } from "../../ducks/app/session/types";
import { loadHideLoadmeterState } from "../../ducks/data/driver-app-settings";
import AddCubicMetersPage from "./AddCubicMeters";
import AddRefPage from "./AddRefPage";

const StopRoutes = ({ sessionId }: { sessionId: string }) => {
  const { stopId } = useParams();
  const stop = useSelector(selectStop(stopId || ""));
  const { t } = useTranslation();
  if (!stop) return null;
  return (
    <>
      <Typography variant="h5" component="h1">
        {stop.type === "PICKUP" ? `${t("Pickup")}` : `${t("Dropoff")}`}
      </Typography>
      <Routes>
        <Route index element={<StopPage stop={stop} sessionId={sessionId} />} />
        <Route
          path="add-note"
          element={<AddNotePage stop={stop} sessionId={sessionId} />}
        />
        <Route
          path="add-weight"
          element={<AddWeightPage stop={stop} sessionId={sessionId} />}
        />
        <Route
          path="add-cubic-meters"
          element={<AddCubicMetersPage stop={stop} sessionId={sessionId} />}
        />
        <Route
          path="add-ref"
          element={<AddRefPage stop={stop} sessionId={sessionId} />}
        />
      </Routes>
    </>
  );
};

const WaypointRoutes = () => {
  const { waypointId } = useParams();
  const waypoint = useSelector(selectWaypoint(waypointId || ""));
  const { t } = useTranslation("waypointPage");
  if (!waypoint) return null;
  return (
    <>
      <Typography variant="h5" component="h1">
        {t("waypoint")}
      </Typography>
      <Routes>
        <Route
          index
          element={<WaypointPage waypoint={waypoint as Waypoint} />}
        />
      </Routes>
    </>
  );
};

const SessionsPage = () => {
  const { sessionId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(navigationActions.setCurrentSessionId(sessionId));
    dispatch(sessionActions.loadSession(sessionId || ""));
    dispatch(loadHideLoadmeterState());
    return () => {
      dispatch(navigationActions.clearCurrentSessionId());
    };
  }, [dispatch, sessionId]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(sessionActions.pingCurrentLocationIfDriver());
  //   }, 10000);
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, [dispatch]);

  if (!sessionId) {
    return null;
  }

  return (
    <Routes>
      <Route index element={<SessionPage sessionId={sessionId} />} />
      <Route
        path="stops/:stopId/*"
        element={<StopRoutes sessionId={sessionId} />}
      />
      <Route path="waypoints/:waypointId/*" element={<WaypointRoutes />} />
      <Route
        path="cargos/:cargoId/add-dropoff"
        element={<AddDropoffPage sessionId={sessionId} />}
      />
      <Route
        path="cargos/add"
        element={<AddCargoPage sessionId={sessionId} />}
      />
      <Route
        path="cargos/add/success"
        element={<AddCargoSuccessPage sessionId={sessionId} />}
      />
    </Routes>
  );
};

export default withAuth(SessionsPage);
